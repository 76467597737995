import apiApp from '@/api/config/apiApp'

function getPQRS (residentialId) {
  return apiApp.get(`/pqrs/residential/${residentialId}`)
}

function checkOpenPQRSByResidential (residentialId) {
  return apiApp.get(`/pqrs/residential/${residentialId}/checkOpenPQRS`)
}

function createPQRS (params) {
  return apiApp.post('/pqrs', params)
}

function updatePQRS (id, params) {
  return apiApp.put(`/pqrs/${id}`, params)
}

function changePQRSImportance (id, params) {
  // console.log('changing importance', params)
  return apiApp.post(`/pqrs/${id}/markPin`, params)
}

function changePQRSActivationStatus (id, params) {
  // console.log('changing activation from api', params)
  return apiApp.post(`/pqrs/${id}/setOpen`, params)
}

function deletePQRS (id) {
  return apiApp.delete(`/pqrs/${id}`)
}

export {
  getPQRS,
  checkOpenPQRSByResidential,
  createPQRS,
  updatePQRS,
  deletePQRS,
  changePQRSImportance,
  changePQRSActivationStatus
}
