import storage from '@/utils/storage'
import * as axios from 'axios'
import { signIn, signUp, resetPassword, validatePassword } from '@/api/auth'
import globals from '@/utils/globals'

const TOKEN = globals.storageNames.authTokenKeyName
const USER_EMAIL = globals.storageNames.userEmail

export default {
  namespaced: true,
  state: {
    token: storage.get(TOKEN) || '',
    email: storage.get(USER_EMAIL) || '',
    error: false
  },
  mutations: {
    SET_EMAIL (state, payload) {
      state.email = payload
    },
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    AUTH_ERROR (state) {
      state.error = true
    }
  },
  getters: {
    isAuthenticated: state => (state.token !== ''),
    authToken: state => state.token
  },
  actions: {
    logIn ({ commit, dispatch }, payload) {
      commit('loading/SET_LOADING', true, { root: true })
      return new Promise((resolve, reject) => {
        signIn(payload)
          .then(resp => {
            dispatch('oauth/saveAuthData', resp.data, { root: true })
            // console.log('auth signin data is ', resp.data)
            resolve(resp)
          })
          .catch(err => {
            commit('AUTH_ERROR')
            storage.remove(TOKEN)
            reject(err)
          })
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },

    signUp ({ commit, dispatch }, payload) {
      commit('loading/SET_LOADING', true, { root: true })
      return new Promise((resolve, reject) => {
        signUp(payload)
          .then(resp => {
            dispatch('oauth/saveAuthData', resp.data, { root: true })
            resolve(resp)
          })
          .catch(err => {
            commit('AUTH_ERROR')
            storage.remove(TOKEN)
            reject(err.response)
          })
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },

    saveAuthData ({ commit, dispatch }, { id, firstName, lastName, email, token }) {
      storage.set(TOKEN, token, true)
      commit('SET_TOKEN', token)
      commit('user/SET_USER', { id, firstName, lastName, email }, { root: true })
      storage.set(USER_EMAIL, email)
      dispatch('residential/loadResidentials', null, { root: true })
    },

    resetPassword ({ commit }, payload) {
      commit('loading/SET_LOADING', true, { root: true })
      return new Promise((resolve, reject) => {
        resetPassword(payload.token, payload.password)
          .then(() => {
            storage.set(TOKEN, payload.token)
            resolve(true)
          })
          .catch(err => {
            // console.log(err)
            commit('AUTH_ERROR')
            storage.remove(TOKEN)
            reject(err)
          })
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },
    resetPasswordUserFromResidentApp ({ commit }, payload) {
      commit('loading/SET_LOADING', true, { root: true })
      return new Promise((resolve, reject) => {
        resetPassword(payload.token, payload.password)
          .then(() => {
            resolve(true)
          })
          .catch(err => {
            commit('AUTH_ERROR')
            reject(err)
          })
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },

    logOut: ({ commit, dispatch }) => {
      delete axios.defaults.headers.common.Authorization
      return new Promise(resolve => {
        storage.remove(TOKEN)
        storage.clear()

        commit('SET_EMAIL', null)
        commit('SET_TOKEN', '')
        dispatch('residential/setActiveResidential', {}, { root: true })
        resolve()
      })
    },

    validatePassword: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        validatePassword(payload.token, payload.password)
          .then((r) => {
            // storage.set(TOKEN, payload.token)
            resolve(true)
          })
          .catch(err => {
            // console.log(err)
            // commit('AUTH_ERROR')
            // storage.remove(TOKEN)
            reject(err)
          })
      })
    }
  }
}
