import {
  listResidentsByResidential,
  createResident,
  updateResident,
  removeUserResidential,
  changeStatusResident,
  changeUserRole,
  getUserRoles,
  meResidential,
  listMembersByResidential
} from '../../api/user'
import globals from '../../utils/globals'
import { RolesEnum } from '@/utils/roles.enum.js'

export default {
  namespaced: true,
  state: {
    user: {},
    meAdminInfo: {},
    userMessageId: null,
    residents: [],
    socketId: null,
    residentsDictionary: {},
    residentialMembersDictionary: {}
  },
  mutations: {
    SET_RESIDENTIAL_MEMBERS (state, payload) {
      state.residentialMembersDictionary = Object.entries(payload).reduce((acc, membersByRoleInfo) => {
        const [role, members] = membersByRoleInfo
        // console.log('role --->', role, 'members --->', members)
        acc[RolesEnum[role]] = members.reduce((accInner, member) => {
          const { id, ...allOtherInfo } = member
          accInner[`${id}`] = allOtherInfo
          return accInner
        }, {})
        return acc
      }, {})
      // console.log('final state.residentialMembersDictionary --->', state.residentialMembersDictionary)
    },
    SET_RESIDENTS (state, payload) {
      state.residents = payload
      state.residentsDictionary = payload.reduce((acc, resident) => {
        const { id, ...allOtherInfo } = resident
        acc[id] = allOtherInfo
        return acc
      }, {})
      // console.log('state.residentsDictionary --->', state.residentsDictionary)
    },
    SET_USER (state, payload) {
      state.user = payload
    },
    SET_SOCKETID (state, payload) {
      state.socketId = payload
    },
    SET_USERMESSAGEID (state, payload) {
      state.userMessageId = payload
    },
    SET_ME_ADMIN_INFO (state, payload) {
      state.meAdminInfo = payload
    }
  },
  getters: {
    residents: state => state.residents,
    user: state => state.user,
    socketId: state => state.socketId,
    userMessageId: state => state.userMessageId,
    residentsDictionary: state => state.residentsDictionary,
    meAdminInfo: state => state.meAdminInfo,
    residentialMembersDictionary: state => state.residentialMembersDictionary
  },
  actions: {
    loadResidents ({ commit }, residentialId) {
      return new Promise((resolve, reject) => {
        commit('loading/SET_LOADING', true, { root: true })
        listResidentsByResidential(residentialId)
          .then(({ data }) => {
            commit('SET_RESIDENTS', data)
            resolve(data)
          })
          .catch((err) => reject(err))
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },

    loadResidentialMembers ({ commit }, payload) {
      // console.log('incoming to query members payload from loadResidentialMembers store', payload)
      return new Promise((resolve, reject) => {
        commit('loading/SET_LOADING', true, { root: true })
        listMembersByResidential(payload)
          .then(({ data }) => {
            // console.log('logging data from loadResidentialMembers store', data)
            commit('SET_RESIDENTIAL_MEMBERS', data)
            resolve(data)
          })
          .catch((err) => {
            // console.log(err)
            reject(err)
          })
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },

    loadUser ({ commit }, residentialId) {
      return new Promise((resolve, reject) => {
        commit('loading/SET_LOADING', true, { root: true })
        meResidential(true, residentialId).then(({ data }) => {
          commit('SET_USER', data)
          resolve(data)
        })
          .catch((err) => reject(err))
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },

    createResident ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        createResident(payload).then(() => {
          dispatch('loadResidents', payload.residentialId)
          resolve(true)
        })
          .catch((err) => reject(err))
      })
    },

    updateResident ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        updateResident(payload.id, payload.data).then(() => {
          dispatch('loadResidents', payload.data.residentialId)
          resolve(true)
        })
          .catch((err) => reject(err))
      })
    },

    removeResident ({ commit, state }, userResidentialId) {
      return new Promise((resolve, reject) => {
        removeUserResidential(userResidentialId).then(() => {
          const residents = state.residents
          const index = residents.findIndex(x => x.userResidentialId === userResidentialId)
          if (index >= 0) {
            residents.splice(index, 1)
            commit('SET_RESIDENTS', residents)
          }
          resolve(true)
        })
          .catch((err) => reject(err))
      })
    },

    changeStatusResident ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        changeStatusResident(payload).then(() => {
          const residents = state.residents
          const index = residents.findIndex(x => x.userResidentialId === payload.userResidentialId)
          if (index >= 0) {
            residents[index].status = payload.isActive ? globals.status.ACTIVE : globals.status.INACTIVE
            if (payload.isActive) {
              residents[index].activedAt = new Date()
            }
            commit('SET_RESIDENTS', residents)
          }
          resolve(true)
        })
          .catch((err) => reject(err))
      })
    },

    changeUserRole ({ commit, state, dispatch }, payload) {
      // console.log('changeUserRole', payload)
      return new Promise((resolve, reject) => {
        changeUserRole(payload).then(() => {
          dispatch('loadResidents', payload.residentialId)
          resolve(true)
        })
          .catch((err) => [console.log('modules', err), reject(err)])
      })
    },

    getUserRole ({ commit, state, dispatch }, { residentialId, userId }) {
      console.log('modules', { residentialId, userId })
      return new Promise((resolve, reject) => {
        getUserRoles(residentialId, userId).then((data) => {
          resolve(data)
        })
          .catch((err) => [console.log('modules', err), reject(err)])
      })
    },

    setSocketId ({ commit }, payload) {
      commit('SET_SOCKETID', payload)
    },

    setUserMessageId ({ commit }, payload) {
      commit('SET_USERMESSAGEID', payload)
    },

    setMeAdminInfo ({ commit }, payload) {
      commit('SET_ME_ADMIN_INFO', payload)
    }
  }
}
