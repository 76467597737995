import { createApp } from 'vue'
import App from './App.vue'

// Styles
import 'bootstrap/dist/css/bootstrap.css'
import 'fomantic-ui-css/semantic.min.css'
import './assets/css/main.css'

// Plugins
import i18n from './plugins/i18n'
import SocketIO from './plugins/socket.io'

import store from './store'
import router from './router'

import globals from './utils/globals'

// Sweeralert
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

// Multiselect
import Multiselect from 'vue-multiselect'
import VTooltip from 'v-tooltip'

const app = createApp(App)

// Dispatch the initializeLanguage action before mounting the app
store.dispatch('language/initializeLanguage').then(() => {
  app.use(store)
  app.use(i18n)
  app.use(router)
  app.use(VueSweetalert2, {
    confirmButtonColor: '#436f98',
    cancelButtonColor: '#88c6df'
  })
  app.use(Multiselect)
  app.use(SocketIO)
  app.use(VTooltip)
  app.mixin({ data: () => globals })
  app.mount('#app')
})
