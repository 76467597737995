import apiApp from '@/api/config/apiApp'

function getResidential (residentialId) {
  return apiApp.get(`/residential/${residentialId}`)
}

function getResidentialDemo (residentialId) {
  return apiApp.get(`/residential-demo/${residentialId}`)
}

function getResidentials () {
  return apiApp.get('/residential')
}

function getResidentialsDemo () {
  return apiApp.get('/residential-demo')
}

function createResidential (data) {
  return apiApp.post('/residential', data)
}

function updateResidential (id, data) {
  return apiApp.patch(`/residential/${id}`, data)
}

function updateStatusResidential (id, active) {
  return apiApp.patch(`/residential/${id}/status`, { active })
}

function deleteResidential (id) {
  return apiApp.delete(`/residential/${id}`)
}

export {
  getResidential,
  getResidentialDemo,
  getResidentials,
  getResidentialsDemo,
  createResidential,
  updateResidential,
  updateStatusResidential,
  deleteResidential
}
