import { listReservations } from '@/api/reservation'

export default {
  namespaced: true,
  state: {
    reservations: [],
    dateReservations: [],
    activeReservation: 0
  },
  mutations: {
    SET_RESERVATIONS (state, payload) {
      state.reservations = payload
    },
    SET_DATE_RESERVATIONS (state, payload) {
      state.dateReservations = payload
    },
    SET_ACTIVE_RESERVATION (state, payload) {
      state.activeReservation = payload
    },
    CANCEL_RESERVATION (state, payload) {
      const idxDateReservCancel = state.dateReservations.findIndex(elem => elem.id === payload.id)
      state.dateReservations[idxDateReservCancel].status = 'CANCELLED'
      state.reservations = state.reservations.filter(elem => elem.id !== payload.id)
    }
  },
  getters: {
    reservations: state => state.reservations,
    activeReservation: state => state.activeReservation,
    dateReservations: state => state.dateReservations
  },
  actions: {
    loadDateReservations ({ commit }, payload) {
      // console.log('log loadDateReservations before all reservations with payload ', payload)
      return new Promise((resolve, reject) => {
        listReservations(payload)
          .then(({ data }) => {
            // console.log('RESERVATION PRE Z REMOVAL --->', data)
            data.forEach(res => {
              res.reservationStart = res.reservationStart.replace('Z', '')
              res.reservationEnd = res.reservationEnd.replace('Z', '')
            })
            // console.log('RESERVATION POST Z REMOVAL --->', data)
            commit('SET_DATE_RESERVATIONS', data)
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },
    setActiveReservation ({ commit }, payload) {
      // console.log('log setActiveReservation before all reservations with payload ', payload)
      commit('SET_ACTIVE_RESERVATION', payload)
    },
    setReservations ({ commit }, payload) {
      // console.log('log setReservations before all reservations with payload ', payload)
      commit('SET_RESERVATIONS', payload)
    },
    addReservations ({ commit, state }, payload) {
      const newReservations = []
      payload.forEach(reservation => {
        if (!state.reservations.find(x => x.id === reservation.id)) {
          newReservations.push(reservation)
        }
      })
      commit('SET_RESERVATIONS', [...state.reservations, ...newReservations])
    },
    updateReservation ({ commit, state }, payload) {
      const reservations = state.reservations
      const index = reservations.findIndex(x => x.id === payload.id)
      if (index >= 0) {
        reservations[index] = payload
        commit('SET_RESERVATIONS', reservations)
      }
    },
    cancelReservation ({ commit, state }, payload) {
      commit('CANCEL_RESERVATION', payload)
    }
  }
}
