import apiApp from '@/api/config/apiApp'

function listResidentsByResidential (residentialId) {
  return apiApp.get(`/users/residents/residential/${residentialId}`)
}

function listMembersByResidential (data) {
  const query = new URLSearchParams()
  query.append('residentialId', data.residentialId)
  data.membersRoles.forEach((role) => {
    query.append('membersRoles', role)
  })
  return apiApp.get(`/users/members/residential?${query}`)
}

function createResident (user) {
  return apiApp.post('/users', user)
}

function updateResident (userId, data) {
  return apiApp.put(`/users/resident/${userId}`, data)
}

function removeUserResidential (userResidentialId) {
  return apiApp.delete(`/users/userResidential/${userResidentialId}`)
}

function changeStatusResident (data) {
  return apiApp.post('users/status', data)
}

function me () {
  return apiApp.get('/users/me')
}

function updateProfile (params) {
  return apiApp.put('/users/me', params)
}

function updateLanguage (params) {
  return apiApp.patch('/users/me/settings', params)
}

function changeProfilePic (params) {
  return apiApp.patch('users/me/profilePicture', params)
}

function meResidential (isAdmin, residentialId) {
  return apiApp.get(`/users/me/isAdmin/${isAdmin}/residential/${residentialId}`)
}

function uploadResidents (data) {
  return apiApp.post('/users/uploadResidents', data)
}

function changeUserRole (data) {
  return apiApp.post('/users/changeUserRole', data)
}

function getUserRoles (residentialId, userId) {
  // console.log('api', { residentialId, userId })
  return apiApp.get(`users/roles/${residentialId}/${userId}`)
}

export {
  listResidentsByResidential,
  createResident,
  updateResident,
  removeUserResidential,
  changeStatusResident,
  me,
  meResidential,
  uploadResidents,
  updateProfile,
  changeProfilePic,
  listMembersByResidential,
  changeUserRole,
  getUserRoles,
  updateLanguage
}
