import {
  listMessages,
  getConversation,
  createConversation,
  pushMessage,
  editConversationMembers
} from '@/api/messaging'

export default {
  namespaced: true,
  state: {
    messages: [],
    received: [],
    sent: [],
    unread: [],
    active: null,
    conversationActive: null,
    residentialMembersToMessage: []
  },
  mutations: {
    SET_MESSAGES (state, payload) {
      state.messages = payload
    },
    SET_RECEIVED (state, payload) {
      state.received = payload
    },
    SET_SENT (state, payload) {
      state.sent = payload
    },
    SET_ACTIVE (state, payload) {
      state.active = payload
    },
    SET_CONVERSATION_ACTIVE (state, payload) {
      state.conversationActive = payload
    },
    SET_UNREAD (state, payload) {
      state.unread = payload
    },
    ADD_TO_RECEIVED (state, payload) {
      state.received.push(payload)
    },
    ADD_TO_SENT (state, payload) {
      state.sent.push(payload)
    },
    SET_RESIDENTIAL_MEMBERS_TO_MESSAGE (state, payload) {
      state.residentialMembersToMessage = payload
    }
  },
  getters: {
    messages: state => state.messages.sort((a, b) => sortByDate(a, b)),
    received: state => state.received.sort((a, b) => sortByDate(a, b)),
    sent: state => state.sent.sort((a, b) => sortByDate(a, b)),
    active: state => state.active,
    conversationActive: state => state.conversationActive,
    unread: state => state.unread,
    residentialMembersToMessage: state => state.residentialMembersToMessage
  },
  actions: {
    setResidentialMembersToMessage ({ commit }, payload) {
      // console.log('CHANGE FROM MESSAGING STORE - SET RESIDENTS TO MESSAGE', payload)
      commit('SET_RESIDENTIAL_MEMBERS_TO_MESSAGE', payload)
    },
    setReceived ({ commit }, payload) {
      commit('SET_RECEIVED', payload)
    },
    setSent ({ commit }, payload) {
      commit('SET_SENT', payload)
    },
    setUnread ({ commit }, payload) {
      commit('SET_UNREAD', payload)
    },
    setActive ({ commit }, payload) {
      commit('SET_ACTIVE', payload)
    },
    setConversationActive ({ commit }, payload) {
      commit('SET_CONVERSATION_ACTIVE', payload)
    },
    loadMessages ({ commit }, payload) {
      const { residentialId, sessionUserId } = payload
      return new Promise((resolve, reject) => {
        listMessages(residentialId)
          .then(({ data }) => {
            commit('SET_MESSAGES', data)
            // ----- setting unread messages when loading messages
            if (sessionUserId) {
              const unreadMessages = []
              data.forEach(message => {
                if (message.lastMessage.user._id !== sessionUserId.toString() && message.lastMessage.seen.indexOf(sessionUserId.toString()) === -1) {
                  unreadMessages.push(message)
                }
              })
              commit('SET_UNREAD', unreadMessages)
            }
            // -----
            resolve(data)
          }).catch((error) => reject(error))
      })
    },
    getConversationDetail ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getConversation(payload)
          .then(({ data }) => {
            const { returnMessages } = data
            commit('SET_ACTIVE', returnMessages)
            resolve(data)
          }).catch((error) => reject(error))
      })
    },
    createConversation ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        createConversation(payload)
          .then(({ data }) => {
            const messages = state.messages
            const indexConversation = messages.findIndex(message => message.uid === data.uid)
            if (!(indexConversation >= 0)) {
              commit('SET_MESSAGES', [...state.messages, data])
            }
            resolve(data)
          }).catch((error) => reject(error))
      })
    },
    editConversationMembers ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        editConversationMembers(payload.conversationId, payload.data)
          .then(({ data }) => {
            commit('SET_CONVERSATION_ACTIVE', { ...state.conversationActive, participants: data.participants })
            resolve(data)
          }).catch((error) => reject(error))
      })
    },
    pushMessage ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        pushMessage(payload.conversationId, payload.data)
          .then(({ data }) => {
            commit('SET_ACTIVE', [...state.active, data])
            commit('SET_CONVERSATION_ACTIVE', { ...state.conversationActive, lastMessage: data })

            const messages = state.messages
            const indexConversation = messages.findIndex(message => message.uid === data.conversationId)
            if (indexConversation >= 0) {
              messages[indexConversation].lastMessage = data
              commit('SET_MESSAGES', messages)
            }

            resolve(data)
          }).catch((error) => reject(error))
      })
    },
    newMessageReceived ({ commit, state }, payload) {
      const messages = state.messages
      const indexConversation = messages.findIndex(message => message.uid === payload.uid)
      if (indexConversation >= 0) {
        messages[indexConversation] = payload
      } else {
        messages.push(payload)
      }
      commit('SET_MESSAGES', messages)

      if (state.conversationActive.uid === payload.uid) {
        commit('SET_CONVERSATION_ACTIVE', payload)
      }

      if (state.active && state.active[0].conversationId === payload.uid) {
        commit('SET_ACTIVE', [...state.active, payload.lastMessage])
      }
    },
    removeUnread ({ commit, state }, payload) {
      const unread = state.unread
      const index = unread.findIndex(msg => msg.uid === payload)
      if (index >= 0) {
        unread.splice(index, 1)
        commit('SET_UNREAD', unread)
      }
    }
    // createMessage ({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     listMessages(payload)
    //       .then((r) => {
    //         commit('SET_MESSAGES', r.data)
    //         resolve(r)
    //       }).catch((error) => reject(error))
    //   })
    // },
    // addToReceived ({ commit, state, rootGetters }, payload) {
    //   const index = state.received.findIndex(msg => msg.uid === payload.uid)
    //   if (index >= 0) {
    //     const received = state.received
    //     received[index] = payload
    //     commit('SET_RECEIVED', received)
    //   } else {
    //     commit('ADD_TO_RECEIVED', payload)
    //   }
    //   if (state.active && state.active.uid === payload.uid) {
    //     state.active = payload
    //   }

    //   const user = rootGetters['user/user']
    //   if (payload.replies.length === 0 && payload.user.userId !== user.id) {
    //     commit('SET_UNREAD', [...state.unread, payload])
    //   } else if (payload.replies[payload.replies.length - 1].user.userId !== user.id) {
    //     commit('SET_UNREAD', [...state.unread, payload])
    //   }
    // },
    // addToSent ({ commit, state }, payload) {
    //   const index = state.sent.findIndex(msg => msg.uid === payload.uid)
    //   if (index >= 0) {
    //     const sent = state.sent
    //     sent[index] = payload
    //     commit('SET_SENT', sent)
    //   } else {
    //     commit('ADD_TO_SENT', payload)
    //   }
    //   if (state.active && state.active.uid === payload.uid) {
    //     state.active = payload
    //   }
    // },

  }
}

const sortByDate = (a, b) => {
  return new Date(b.lastMessage?.createdAt).getTime() - new Date(a.lastMessage?.createdAt).getTime()
}
