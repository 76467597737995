export default [{
  path: '/spacesManagement',
  meta: { requiresAuth: true, requiresResidential: true },
  component: () => import('@/layouts/MainTemplate'),
  children: [
    {
      path: '',
      component: () => import('@/views/Spaces/SpaceManager')
    }
  ]
}]
