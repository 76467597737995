import { getSurveys, getSurveyStats } from '@/api/surveys'

export default {
  namespaced: true,
  state: {
    surveys: [],
    survey: {}
  },
  mutations: {
    SET_SURVEYS (state, payload) {
      // console.log('setting dummy surveys ', payload)
      // console.log('surveys', payload)
      state.surveys = payload
    },
    SET_SURVEY (state, payload) {
      // console.log('setting dummy surveys ', payload)
      // console.log('surveys', payload)
      state.survey = payload
    },
    ADD_NEW_SURVEY (state, payload) {
      // console.log('ejnfjrnvrijenjkrnjr', payload)
      state.surveys.push(payload)
    },
    UPDATE_SURVEY (state, payload) {
      const idx = state.surveys.findIndex(elem => elem.id === payload.id)
      state.surveys[idx] = payload
    },
    DELETE_SURVEY (state, payload) {
      state.surveys = state.surveys.filter(elem => elem.id !== payload.id)
    }
  },
  getters: {
    allSurveys: state => state.surveys,
    surveyById: (state, getters) => id => getters.allSurveys.find(elem => elem.id === id),
    stats: state => state.survey
  },
  actions: {
    loadSurveys ({ commit }, payload) {
      // console.log('jinvjeibnvhernvjhernvherjvrenjhvbr')
      return new Promise((resolve, reject) => {
        getSurveys(payload)
          .then((r) => {
            // console.log('raw data')
            // console.log(r.data)
            const formattedSurveys = r.data.map(survey => {
              return {
                id: survey.id,
                title: survey.name,
                description: survey.description,
                activatedAt: survey.activatedAt,
                expiresAt: survey.expiresAt,
                active: survey.status === 'ACTIVE'
              }
            })

            commit('SET_SURVEYS', formattedSurveys)
            resolve(r)
          }).catch((error) => reject(error))
      })
    },
    getStats ({ commit }, payload) {
      // console.log('jinvjeibnvhernvjhernvherjvrenjhddddvbr', payload)
      return new Promise((resolve, reject) => {
        getSurveyStats(payload)
          .then((r) => {
            // console.log('raw data')
            // console.log(r.data)
            const survey = {}
            survey.yes = r.data.yes
            survey.no = r.data.no
            survey.totalResponses = r.data.totalResponses

            // console.log('hjbvjbervhejrbvherbver', survey)
            commit('SET_SURVEY', survey)
            resolve(r)
          }).catch((error) => reject(error))
      })
    },

    addNewSurvey ({ commit }, payload) {
      // console.log('jmvjinrbjkrbnrkbnrjknbr')
      commit('ADD_NEW_SURVEY', payload)
    },

    update_Survey ({ commit }, payload) {
      commit('UPDATE_SURVEY', payload)
    },

    delete_Survey ({ commit }, payload) {
      commit('DELETE_SURVEY', payload)
    }
  }
}
