import VueSocketIO from 'vue-3-socket.io'
import globals from '../utils/globals'

export default new VueSocketIO({
  debug: true,
  connection: globals.socketUrl
  // vuex: {
  //   store,
  //   actionPrefix: 'SOCKET_',
  //   mutationPrefix: 'SOCKET_'
  // }
})
