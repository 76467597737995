<template>
  <div class="loading-layout">
    <img src="@/assets/img/loading.gif" />
  </div>
</template>

<script>
export default {
  name: 'LoadLayout'
}
</script>

<style scoped>
  .loading-layout {
    background-color: rgba(0,0,0,0.3);
    min-height: 100vh;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1005 !important;
  }
</style>
