import { getBulletins } from '@/api/bulletinBoard'

export default {
  namespaced: true,
  state: {
    bulletins: []
  },
  mutations: {
    SET_BULLETINS (state, payload) {
      // console.log('setting dummy bulletins ', payload)
      state.bulletins = payload
    },
    ADD_NEW_BULLETIN (state, payload) {
      state.bulletins.push(payload)
    },
    UPDATE_BULLETIN (state, payload) {
      const idx = state.bulletins.findIndex(elem => elem.id === payload.id)
      state.bulletins[idx] = payload
    },
    DELETE_BULLETIN (state, payload) {
      state.bulletins = state.bulletins.filter(elem => elem.id !== payload.id)
    }
  },
  getters: {
    allBulletins: state => state.bulletins,
    bulletinById: (state, getters) => id => getters.allBulletins.find(elem => elem.id === id)
  },
  actions: {
    loadBulletins ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getBulletins(payload)
          .then((r) => {
            // console.log('raw data')
            // console.log(r.data)
            const formattedBulletins = r.data.map(bulletin => {
              return {
                id: bulletin.id,
                important: bulletin.isImportant,
                title: bulletin.title,
                coverPictureURL: bulletin.coverPicture,
                Description: bulletin.body,
                files: bulletin.attachment,
                likes: bulletin.likes,
                createdAt: bulletin.createdAt,
                activatedAt: bulletin.activatedAt,
                expiresAt: bulletin.expiresAt,
                active: bulletin.status === 'ACTIVE',
                link: bulletin.link
              }
            })

            commit('SET_BULLETINS', formattedBulletins)
            resolve(r)
          }).catch((error) => reject(error))
      })
    },

    addNewBulletin ({ commit }, payload) {
      commit('ADD_NEW_BULLETIN', payload)
    },

    update_Bulletin ({ commit }, payload) {
      commit('UPDATE_BULLETIN', payload)
    },

    delete_Bulletin ({ commit }, payload) {
      commit('DELETE_BULLETIN', payload)
    }
  }
}
