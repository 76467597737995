import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

import rootRoutes from './root'
import authRoutes from './auth'
import homeRoutes from './home'
import residentialRoutes from './residential'
import messagingRoutes from './messaging'
import bulletinBoardRoutes from './bulletinBoard'
import residentsRoutes from './residents'
import spacesManagementRoutes from './spacesManagement'
import directoryRoutes from './directory'
import profileRoutes from './profile'
import guardRoutes from './guard'
import chargeAccountRoutes from './chargeAccount'
import pqrsRoutes from './pqrs'
import surveysRoutes from './surveys'
import digitalLibraryRoutes from './digitalLibrary'

const routes = [
  ...rootRoutes,
  ...homeRoutes,
  ...authRoutes,
  ...residentialRoutes,
  ...messagingRoutes,
  ...bulletinBoardRoutes,
  ...residentsRoutes,
  ...spacesManagementRoutes,
  ...directoryRoutes,
  ...profileRoutes,
  ...guardRoutes,
  ...chargeAccountRoutes,
  ...pqrsRoutes,
  ...digitalLibraryRoutes,
  ...surveysRoutes
]

// 404 Not Found page
routes.push({ path: '/:pathMatch(.*)*', redirect: { path: '/' } })

const router = createRouter({
  base: '/',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Validate meta fields
router.beforeEach((to, from, next) => {
  // eslint-disable-next-line
  if (to.path === '/' && store.getters['oauth/isAuthenticated'] || to.path === '/guard') {
    next({ path: '/home' })
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Authentication
    if (!store.getters['oauth/isAuthenticated']) {
      next({ path: '/auth/signin' })
    }
  }
  if (to.matched.some(record => record.meta.requiresResidential)) {
    // Residential active
    const residential = store.getters['residential/activeResidential']
    if (!residential.residentialId) {
      next({ path: '/home' })
    }
  }
  // console.log('log from path router ---->', from.path)
  // console.log('log to path router ---->', to.path)
  store.dispatch('routes/setFromRoute', from.path)
  store.dispatch('routes/setToRoute', to.path)
  next()
})

export default router
