export default [{
  path: '/residential',
  meta: { requiresAuth: true, requiresResidential: true },
  component: () => import('@/layouts/MainTemplate'),
  children: [
    {
      name: 'residentialAdmin',
      path: '',
      component: () => import('@/views/Residential/Index')
    },
    {
      name: 'residentialEdition',
      path: 'edition',
      component: () => import('@/views/Residential/Edition')
    }
  ]
}]
