export const RolesEnum = {
  ADMINISTRATOR: 1,
  RESIDENT: 2,
  WATCHMAN: 3,
  SUPER_ADMINISTRATOR: 4,
  BACKOFFICE_ADMINISTRATOR: 5,
  VISUALIZER: 6,
  COHABITATION: 7,
  COUNCIL: 8,
  OWNER: 9,
  OCCUPIER: 10,
  TENANT: 11
}
