import apiApp from '@/api/config/apiApp'

function getSurveys (residentialId) {
  return apiApp.get(`/survey/residential/${residentialId}`)
}

function getSurveyStats (id) {
  return apiApp.get(`/survey/${id}/stats`)
}

function createSurvey (params) {
  return apiApp.post('/survey', params)
}

function updateSurvey (id, params) {
  return apiApp.patch(`/survey/${id}`, params)
}

function changeActivationStatus (id, params) {
  // console.log('changing activation from api', params)
  return apiApp.patch(`/survey/${id}/toggle`, params)
}

function deleteSurvey (id) {
  return apiApp.delete(`/survey/${id}`)
}

export {
  getSurveys,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  changeActivationStatus,
  getSurveyStats
}
