// store/modules/language.js
import i18n from '@/plugins/i18n'

export default {
  namespaced: true,
  state: {
    selectedLanguage: ''
  },
  mutations: {
    setSelectedLanguage (state, language) {
      state.selectedLanguage = language
    }
  },
  getters: {
    selectedLanguage: state => state.selectedLanguage
  },
  actions: {
    initializeLanguage ({ commit }) {
      // Load the selected language from local storage
      const selectedLanguage = localStorage.getItem('selectedLanguage')
      if (selectedLanguage) {
        commit('setSelectedLanguage', selectedLanguage)
        i18n.global.locale = selectedLanguage // Set the language in the i18n library
      } else {
        // Use the default language if no language is set in local storage
        const defaultLanguage = 'es' // Replace with your default language code
        commit('setSelectedLanguage', defaultLanguage)
        i18n.global.locale = defaultLanguage // Set the language in the i18n library
      }
    },
    setLanguage ({ commit }, language) {
      // Update the selected language in the store
      commit('setSelectedLanguage', language)

      // Save the selected language to local storage
      localStorage.setItem('selectedLanguage', language)

      // Set the language in the i18n library
      i18n.global.locale = language
      // console.log('Setting locale:', language)
    }
  }
}
