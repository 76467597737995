import apiApp from '@/api/config/apiApp'

function reportPayment (data) {
  return apiApp.post('/payment/report', data)
}

function chargeAccountReportPayment (data) {
  return apiApp.post('/payment/chargeAccountReport', data)
}

function getZIBIPayRules () {
  return apiApp.get('/payment/rules')
}

export { reportPayment, chargeAccountReportPayment, getZIBIPayRules }
