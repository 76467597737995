import apiApp from '@/api/config/apiApp'

function signIn (user) {
  return apiApp.post('/auth/signin', user)
}

function signUp ({ email, password }) {
  return apiApp.post('/auth/signup', { email, password })
}

function forgotPassword (email) {
  return apiApp.post('/auth/forgotPassword', { email })
}

function resetPassword (token, password) {
  return apiApp.patch('/auth/changePassword', { password }, {
    headers: { Authorization: `Bearer ${token}` }
  })
}

function validatePassword (token, password) {
  return apiApp.post('/auth/validatePassword', { password }, {
    headers: { Authorization: `Bearer ${token}` }
  })
}

export {
  signIn,
  signUp,
  forgotPassword,
  resetPassword,
  validatePassword
}
