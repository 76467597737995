import {
  createChargeAccount,
  listChargeAccountTypes,
  listChargeAccounts,
  changeStatusChargeAccount
} from '../../api/chargeAccount'
import globals from '../../utils/globals'

export default {
  namespaced: true,
  state: {
    chargeAccounts: [],
    types: []
  },
  mutations: {
    SET_CHARGE_ACCOUNTS (state, payload) {
      state.chargeAccounts = payload
    },
    SET_CHARGE_ACCOUNTS_TYPES (state, payload) {
      state.types = payload
    }
  },
  getters: {
    chargeAccounts: state => state.chargeAccounts.sort(
      (a, b) => (b.year - a.year || b.month - a.month)
    ),
    chargeAccountTypes: state => state.types
  },
  actions: {
    loadChargeAccounts ({ commit }, residentialId) {
      return new Promise((resolve, reject) => {
        commit('loading/SET_LOADING', true, { root: true })
        listChargeAccounts(residentialId)
          .then(({ data }) => {
            data.forEach(chargeAccount => {
              chargeAccount.expirationDiscount = chargeAccount.expirationDiscount.replace('Z', '')
              chargeAccount.expirationFee = chargeAccount.expirationFee.replace('Z', '')
            })
            commit('SET_CHARGE_ACCOUNTS', data)
            resolve(data)
          })
          .catch((err) => reject(err))
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },

    loadChargeAccountsTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('loading/SET_LOADING', true, { root: true })
        listChargeAccountTypes()
          .then(({ data }) => {
            commit('SET_CHARGE_ACCOUNTS_TYPES', data)
            resolve(data)
          })
          .catch((err) => reject(err))
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },

    createChargeAccount ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit('loading/SET_LOADING', true, { root: true })
        createChargeAccount(payload).then((response) => {
          // console.log('createChargeAccount response ---->', response.data)
          const createdChargedAccount = response.data
          createdChargedAccount.expirationDiscount = createdChargedAccount.expirationDiscount.replace('Z', '')
          createdChargedAccount.expirationFee = createdChargedAccount.expirationFee.replace('Z', '')
          commit('SET_CHARGE_ACCOUNTS', [
            createdChargedAccount,
            ...state.chargeAccounts
          ])
          resolve(true)
        })
          .catch((err) => reject(err))
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },

    changeStatusChargeAccount ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        changeStatusChargeAccount(payload).then(() => {
          const chargeAccounts = state.chargeAccounts
          const index = chargeAccounts.findIndex(x => x.id === payload.id)
          if (index >= 0) {
            chargeAccounts[index].status = payload.isActive ? globals.status.ACTIVE : globals.status.INACTIVE
            commit('SET_CHARGE_ACCOUNTS', chargeAccounts)
          }
          resolve(true)
        })
          .catch((err) => reject(err))
      })
    }
  }
}
