import {
  getRootDigitalLibraryOfResidential,
  uploadDigitalLibraryFile,
  deleteDigitalLibraryFile,
  downloadFile
} from '@/api/digitalLibrary'

export default {
  namespaced: true,
  state: {
    activeResidentialDigitalLibrary: {},
    routeToNavigate: null
  },
  mutations: {
    SET_ACTIVE_RESIDENTIAL_DIGITAL_LIBRARY (state, payload) {
      state.activeResidentialDigitalLibrary = payload
    },
    SET_ROUTE_TO_NAVIGATE (state, payload) {
      state.routeToNavigate = payload
    }
  },
  getters: {
    activeResidentialDigitalLibrary: state => state.activeResidentialDigitalLibrary,
    routeToNavigate: state => state.routeToNavigate
  },
  actions: {
    setRouteToNavigate ({ commit }, payload) {
      // console.log('setRouteToNavigate', payload)
      commit('SET_ROUTE_TO_NAVIGATE', payload)
    },

    loadRootDigitalLibraryOfResidential ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getRootDigitalLibraryOfResidential(payload)
          .then((r) => {
            const resDigLibTree = Object.values(r.data)[0].children
            // console.log('resDigLibTree -->', resDigLibTree)
            commit('SET_ACTIVE_RESIDENTIAL_DIGITAL_LIBRARY',
              resDigLibTree
            )
            resolve(r)
          }).catch((error) => reject(error))
      })
    },
    uploadFileInFolder ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        uploadDigitalLibraryFile(payload.folderId, payload.file)
          .then((r) => {
            resolve(r.data)
          }).catch((error) => reject(error))
      })
    },
    deleteFileInFolder ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        deleteDigitalLibraryFile(payload.folderId, payload.fileId)
          .then((r) => {
            resolve(r.data)
          }).catch((error) => reject(error))
      })
    },

    downloadFileFromBackend ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        downloadFile(payload.fileId)
          .then((r) => {
            // console.log('r', r)
            // console.log('downloadFileFromBackend', r.data)
            resolve(r)
          }).catch((error) => reject(error))
      })
    }
  }
}
