import apiApp from '@/api/config/apiApp'

function getRootDigitalLibraryOfResidential (residentialId) {
  return apiApp.get(`/digital-library/${residentialId}/list`)
}

function uploadDigitalLibraryFile (folderId, file) {
  return apiApp.post(`/digital-library/upload/${folderId}`, file)
}

function deleteDigitalLibraryFile (folderId, fileId) {
  return apiApp.delete(`/digital-library/delete/${folderId}/${fileId}`)
}

function downloadFile (fileId) {
  return apiApp.get(`/digital-library/download/${fileId}`)
}

export {
  getRootDigitalLibraryOfResidential,
  uploadDigitalLibraryFile,
  deleteDigitalLibraryFile,
  downloadFile
}
