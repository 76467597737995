export default {
  namespaced: true,
  state: {
    spaceManaged: ''
  },
  mutations: {
    SET_SPACE_TO_MANAGE (state, payload) {
      state.spaceManaged = payload
    }
  },
  getters: {
    getSpaceThatIsBeingManaged: state => state.spaceManaged
  },
  actions: {
    setSpaceToManage ({ commit }, payload) {
      // console.log('the space to manage is ', payload)
      commit('SET_SPACE_TO_MANAGE', payload)
    }
  }
}
