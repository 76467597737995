import {
  getResidentials,
  getResidentialsDemo,
  createResidential,
  updateResidential,
  deleteResidential
} from '../../api/residential'
import globals from '../../utils/globals'
import storage from '../../utils/storage'
import { RolesEnum } from '../../utils/roles.enum'

const ACTIVE_RESIDENTIAL_STORAGE_NAME = globals.storageNames.activeResidential

export default {
  namespaced: true,
  state: {
    activeResidential: storage.get(ACTIVE_RESIDENTIAL_STORAGE_NAME) || {},
    residentials: []
  },
  mutations: {
    SET_ACTIVE_RESIDENTIAL (state, payload) {
      state.activeResidential = payload
    },
    SET_RESIDENTIALS (state, payload) {
      state.residentials = payload
    }
  },
  getters: {
    activeResidential: state => state.activeResidential,
    residentials: state => state.residentials
  },
  actions: {
    loadResidentials ({ commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        commit('loading/SET_LOADING', true, { root: true })
        const isAuthenticated = rootGetters['oauth/isAuthenticated']
        if (isAuthenticated) {
          getResidentials()
            .then(({ data }) => {
              commit('SET_RESIDENTIALS', data)
              resolve(data)
            })
            .catch((err) => reject(err))
            .finally(() => {
              commit('loading/SET_LOADING', false, { root: true })
            })
        } else {
          getResidentialsDemo()
            .then(({ data }) => {
              commit('SET_RESIDENTIALS', data)
              resolve(data)
            })
            .catch((err) => reject(err))
            .finally(() => {
              commit('loading/SET_LOADING', false, { root: true })
            })
        }
      })
    },

    addResidential ({ dispatch, commit }, payload) {
      commit('loading/SET_LOADING', true, { root: true })
      return new Promise((resolve, reject) => {
        createResidential(payload).then(() => {
          dispatch('loadResidentials')
            .then(() => resolve(true))
            .catch((e) => reject(e))
        }).catch(e => {
          reject(e)
        }).finally(() => {
          commit('loading/SET_LOADING', false, { root: true })
        })
      })
    },

    updateResidential ({ dispatch, commit, state }, { id, data }) {
      return new Promise((resolve, reject) => {
        updateResidential(id, data).then(({ data: residential }) => {
          dispatch('loadResidentials')
            .then(() => {
              commit('SET_ACTIVE_RESIDENTIAL', {
                ...state.activeResidential,
                name: residential.name,
                address: residential.address,
                city: residential.city,
                state: residential.state,
                country: residential.country,
                PQRSlabel: residential.PQRSlabel,
                solvencyCertificateLabel: residential.solvencyCertificateLabel
              })
              storage.set(ACTIVE_RESIDENTIAL_STORAGE_NAME, JSON.stringify({
                id: residential.id,
                residentialId: state.activeResidential.residentialId,
                name: residential.name,
                address: residential.address
              }))
              resolve(true)
            })
            .catch((e) => reject(e))
        }).catch(e => reject(e))
      })
    },

    removeResidential ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        deleteResidential(payload).then(() => {
          const newResidentials = state.residentials
          const index = newResidentials.findIndex(x => x.residentialId === payload)
          if (index >= 0) {
            newResidentials.splice(index, 1)
            commit('SET_RESIDENTIALS', newResidentials)
          }
          resolve(true)
        }).catch((e) => reject(e))
      })
    },

    setActiveResidential ({ commit, dispatch }, payload) {
      const residential = {
        id: payload.id,
        residentialId: payload.residentialId,
        name: payload.name || payload.title,
        address: payload.address,
        lockReservationsUntilDays: payload.lockReservationsUntilDays,
        qrLink: payload.qrLink,
        isMaster: payload.isMaster,
        allowChargeAccount: payload.allowChargeAccount,
        masterId: payload.masterId,
        PQRSlabel: payload.PQRSlabel,
        solvencyCertificateLabel: payload.solvencyCertificateLabel
      }
      storage.set(ACTIVE_RESIDENTIAL_STORAGE_NAME, JSON.stringify(residential))
      commit('SET_ACTIVE_RESIDENTIAL', residential)
      if (residential.id) {
        dispatch('user/loadResidents', residential.residentialId, { root: true })
        const residentiaMembersQueryData = {
          residentialId: payload.residentialId,
          membersRoles: [RolesEnum.ADMINISTRATOR]
        }
        dispatch('user/loadResidentialMembers',
          residentiaMembersQueryData,
          { root: true }
        )
        dispatch('socialAreas/load_Areas', residential.residentialId, { root: true })
        dispatch('chargeAccount/loadChargeAccounts', residential.residentialId, { root: true })
      }
    }
  }
}
