import apiApp from '@/api/config/apiApp'
import messagingApi from '@/api/config/messagingApi'

function listMessages (residentialId) {
  return messagingApi.get('/message?residentialId=' + residentialId)
}

function getConversation (conversationId) {
  return messagingApi.get('/message/conversation/' + conversationId)
}

function createConversation (data) {
  return messagingApi.post('/message', data)
}

function pushMessage (conversationId, data) {
  return messagingApi.put(`/message/${conversationId}`, data)
}

function markMessageAsSeen (conversationId) {
  return messagingApi.post(`/message/${conversationId}/seen`)
}

function createMessage (data) { // this function only uploads files to azure. It does not create a message
  return apiApp.post('/message', data)
}

function uploadFiles (files) {
  return apiApp.post('/message/attachment/upload', files)
}

function editConversationMembers (conversationId, data) {
  return messagingApi.patch(`/message/${conversationId}/members`, data)
}

export {
  listMessages,
  getConversation,
  createConversation,
  pushMessage,
  markMessageAsSeen,
  createMessage,
  uploadFiles,
  editConversationMembers
}
