import { getZIBIPayRules } from '@/api/payment'

export default {
  namespaced: true,
  state: {
    ZIBIPayRules: {}
  },
  mutations: {
    SET_ZIBIPAY_RULES (state, payload) {
      state.ZIBIPayRules = payload
    }
  },
  getters: {
    ZIBIPayRules: state => state.ZIBIPayRules
  },
  actions: {
    load_ZIBIPay_rules ({ commit }) {
      return new Promise((resolve, reject) => {
        getZIBIPayRules()
          .then(r => {
            commit('SET_ZIBIPAY_RULES', r.data)
            resolve(r)
          }).catch(error => reject(error))
      })
    }
  }
}
