// import { getBulletins } from '@/api/bulletinBoard'

export default {
  namespaced: true,
  state: {
    fromRoute: '/',
    toRoute: '/'
  },
  mutations: {
    SET_FROM_ROUTE (state, path) {
      // console.log('setting dummy bulletins ', payload)
      state.fromRoute = path
    },
    SET_TO_ROUTE (state, path) {
      // console.log('setting dummy bulletins ', payload)
      state.toRoute = path
    }
  },
  getters: {
    fromRoute: state => state.fromRoute,
    toRoute: state => state.toRoute
  },
  actions: {
    setFromRoute ({ commit }, path) {
      commit('SET_FROM_ROUTE', path)
    },
    setToRoute ({ commit }, path) {
      commit('SET_TO_ROUTE', path)
    }
  }
}
