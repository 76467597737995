import apiApp from '@/api/config/apiApp'

function getSocialAreas (residentialId) {
  return apiApp.get(`/residential/${residentialId}/social-area`)
}

function createSocialArea (params) {
  return apiApp.post('/social-area', params)
}

function updateSocialArea (id, params) {
  return apiApp.put(`/social-area/${id}`, params)
}

function toggleStatusSocialArea (id) {
  return apiApp.patch(`/social-area/${id}/status`)
}

function deleteSocialArea (id) {
  return apiApp.delete(`/social-area/${id}`)
}

/* Social Areas Types */

function getSocialAreaTypes () {
  return apiApp.get('/social-area-type')
}

function createSocialAreaType (params) {
  // console.log(params)
  return apiApp.post('/social-area-type', params)
}

/** Social Area Analytics */

function getSocialAreaAnalytics (id) {
  return apiApp.get(`/social-area/${id}/analitycs`)
}

export {
  getSocialAreas,
  createSocialArea,
  getSocialAreaTypes,
  createSocialAreaType,
  updateSocialArea,
  deleteSocialArea,
  getSocialAreaAnalytics,
  toggleStatusSocialArea
}
