export default [{
  path: '/directory',
  meta: { requiresAuth: true, requiresResidential: true },
  component: () => import('@/layouts/MainTemplate'),
  children: [
    {
      name: 'directoryHome',
      path: '',
      component: () => import('@/views/Directory/Index')
    }
  ]
}]
