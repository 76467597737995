import { getPQRS, checkOpenPQRSByResidential } from '@/api/pqrs'

export default {
  namespaced: true,
  state: {
    pqrs: []
  },
  mutations: {
    SET_PQRS (state, payload) {
      // console.log('setting dummy pqrs ', payload)
      state.pqrs = payload
    },
    ADD_NEW_PQRS (state, payload) {
      state.pqrs.push(payload)
    },
    UPDATE_PQRS (state, payload) {
      const idx = state.pqrs.findIndex(elem => elem.id === payload.id)
      state.pqrs[idx] = payload
    },
    DELETE_PQRS (state, payload) {
      state.pqrs = state.pqrs.filter(elem => elem.id !== payload.id)
    }
  },
  getters: {
    allPQRS: state => state.pqrs,
    pqrsById: (state, getters) => id => getters.allPQRS.find(elem => elem.id === id)
  },
  actions: {
    checkOpenPQRS ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        checkOpenPQRSByResidential(payload)
          .then((r) => {
            resolve(r.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    loadPQRS ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // commit('SET_PQRS', formattedPQRS)
        // resolve(formattedPQRS)
        getPQRS(payload)
          .then((r) => {
            const formattedPQRS = r.data.map(pqrs => {
              // pqrs.createdAt = pqrs.createdAt.replace('Z', '')
              // pqrs.closeAt = pqrs.closeAt.replace('Z', '')
              return {
                id: pqrs.id,
                userId: pqrs.userId,
                important: pqrs.isImportant,
                isOpen: pqrs.isOpen,
                type: pqrs.typeId,
                imageUrl: pqrs.imageUrl,
                description: pqrs.description,
                name: pqrs.name,
                apartment: pqrs.apartment,
                createdAt: pqrs.createdAt ? pqrs.createdAt.replace('Z', '') : pqrs.createdAt,
                closeAt: pqrs.closeAt ? pqrs.closeAt.replace('Z', '') : pqrs.closeAt
              }
            })

            commit('SET_PQRS', formattedPQRS)
            resolve(r)
          }).catch((error) => reject(error))
      })
    },

    addNewPQRS ({ commit }, payload) {
      commit('ADD_NEW_PQRS', payload)
    },

    update_PQRS ({ commit }, payload) {
      commit('UPDATE_PQRS', payload)
    },

    delete_PQRS ({ commit }, payload) {
      commit('DELETE_PQRS', payload)
    }
  }
}
