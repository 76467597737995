export default [{
  path: '/messaging',
  meta: { requiresAuth: true, requiresResidential: true },
  component: () => import('@/layouts/MainTemplate'),
  children: [
    {
      name: 'messaging.view',
      path: 'view',
      component: () => import('@/views/Messaging/Index'),
      // props: { isNew: false, isMassive: false }
      props: { isNew: true, isMassive: true }
    },
    {
      name: 'messaging.direct',
      path: 'direct/:title',
      component: () => import('@/views/Messaging/Index'),
      props: { isNew: true, isMassive: true }
    },
    {
      name: 'messaging.massive',
      // path: 'massive',
      path: '',
      component: () => import('@/views/Messaging/Index'),
      props: { isNew: true, isMassive: true }
    },
    {
      name: 'messaging.edit',
      path: 'edit',
      component: () => import('@/views/Messaging/EditConversation')
    }
  ]
}]
