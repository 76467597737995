import { getSocialAreas, getSocialAreaAnalytics } from '@/api/socialArea'

export default {
  namespaced: true,
  state: {
    areas: [],
    analitycs: {}
  },
  mutations: {
    SET_AREAS (state, payload) {
      state.areas = payload
    },
    SET_ANALYTICS (state, payload) {
      state.analitycs = payload
    },
    ADD_NEW_AREA (state, payload) {
      state.areas.push(payload)
    },
    UPDATE_AREA (state, payload) {
      const idx = state.areas.findIndex(elem => elem.id === payload.id)
      state.areas[idx] = payload
    },
    DELETE_AREA (state, payload) {
      state.areas = state.areas.filter(elem => elem.id !== payload.id)
    }
  },
  getters: {
    allAreas: state => state.areas,
    areasFiltered: (state, getters) => (searchInput) => {
      return getters.allAreas.filter(elem => elem.name.toLowerCase().startsWith(searchInput))
    },
    currentAnalytics: state => state.analitycs
  },
  actions: {
    load_Areas ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getSocialAreas(payload)
          .then((r) => {
            commit('SET_AREAS', r.data)
            resolve(r)
          }).catch((error) => reject(error))
      })
    },

    load_analytics ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('loading/SET_LOADING', true, { root: true })
        getSocialAreaAnalytics(payload)
          .then((r) => {
            commit('SET_ANALYTICS', r.data)
            commit('loading/SET_LOADING', false, { root: true })
            resolve(r)
          })
          .catch((error) => reject(error))
          .finally(() => {
            commit('loading/SET_LOADING', false, { root: true })
          })
      })
    },

    addNewArea ({ commit }, payload) {
      commit('ADD_NEW_AREA', payload)
    },

    delete_Area ({ commit }, payload) {
      commit('DELETE_AREA', payload)
    },

    update_area ({ commit }, payload) {
      commit('UPDATE_AREA', payload)
    }
  }
}
