import apiApp from '@/api/config/apiApp'

function getBulletins (residentialId) {
  return apiApp.get(`/board/residential/${residentialId}`)
}

function createBulletin (params) {
  return apiApp.post('/board', params)
}

function updateBulletin (id, params) {
  return apiApp.put(`/board/${id}`, params)
}

function changeImportance (id, params) {
  // console.log('changing importance', params)
  return apiApp.post(`/board/${id}/markPin`, params)
}

function changeActivationStatus (id, params) {
  // console.log('changing activation from api', params)
  return apiApp.post(`/board/${id}/setStatus`, params)
}

function deleteBulletin (id) {
  return apiApp.delete(`/board/${id}`)
}

export {
  getBulletins,
  createBulletin,
  updateBulletin,
  deleteBulletin,
  changeImportance,
  changeActivationStatus
}
