export default {

  get (name) {
    try {
      const obj = JSON.parse(localStorage.getItem(name))
      return obj
    } catch (error) {
      return localStorage.getItem(name)
    }
  },

  set (name, value) {
    localStorage.setItem(name, value)
  },

  remove (name) {
    localStorage.removeItem(name)
  },

  clear () {
    localStorage.clear()
  }
}
