import apiApp from '@/api/config/apiApp'

function listReservations (params = []) {
  if (params.length === 0) {
    throw new Error('Invalid params')
  }

  const queryParams = params.map(x => `${x.name}=${x.value}`).join('&')
  return apiApp.get(`/reservation?${queryParams}`)
}

function getReservation (id) {
  if (id === 0) return
  return apiApp.get(`/reservation/${id}`)
}

function createReservation (params) {
  return apiApp.post('/reservation', params)
}

function updateReservation (id, params) {
  return apiApp.put(`/reservation/${id}`, params)
}

function deleteReservation (id) {
  return apiApp.delete(`/reservation/${id}`)
}
// function reservationReport (residentialId, initDate, endDate) {
//   return apiApp.get(`/reservation/report?residentialId=${residentialId}&initDate=${initDate}&endDate=${endDate}`)
// }
function reservationReport (params) {
  return apiApp.post('/reservation/report', params)
}

export {
  listReservations,
  getReservation,
  createReservation,
  updateReservation,
  deleteReservation,
  reservationReport
}
