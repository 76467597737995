export default [{
  path: '/guard',
  meta: { requiresAuth: true, requiresResidential: true },
  component: () => import('@/layouts/MainTemplate'),
  children: [
    {
      path: '', component: () => import('@/views/Residential/Index')
    },
    {
      path: 'watchmen',
      component: () => import('@/views/Guard/Watchmen')
    },

    {
      path: 'visits',
      component: () => import('@/views/Guard/Visits')
    },

    {
      path: 'notifications',
      component: () => import('@/views/Guard/Notifications')
    },

    {
      path: 'shortstay',
      component: () => import('@/views/Guard/Shortstay')
    }
  ]
}]
