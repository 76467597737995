<template>
  <LoadLayout v-if="isLoading">
    <Loading/>
  </LoadLayout>
  <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadLayout from './layouts/LoadLayout'

export default {
  name: 'App',
  components: { LoadLayout },
  sockets: {
    connect: function () {
      // console.log('socket connected', this.$socket.id)
    },
    // 'user:setted': function (userMessageId) {
    // console.log('USER:::SETTED', userMessageId)
    //   this.$store.dispatch('user/setUserMessageId', userMessageId)
    //   this.$socket.emit('message:listReceived', { residential: this.activeResidential.residentialId })
    //   this.$socket.emit('message:listSent', { residential: this.activeResidential.residentialId })
    //   this.$socket.emit('message:listAll', { residential: this.activeResidential.residentialId })
    // },
    // 'message:receive': function (message) {
    //   this.$store.dispatch('messaging/addToReceived', message)
    // },
    // 'message:sent': function (message) {
    //   this.$store.dispatch('messaging/addToSent', message)
    // },
    // 'message:listAll': function (messages) {
    //   console.log('list all messages', messages)
    // },
    // 'message:listReceived': function (messages) {
    //   this.$store.dispatch('messaging/setReceived', messages)
    // },
    // 'message:listSent': function (messages) {
    //   this.$store.dispatch('messaging/setSent', messages)
    // },
    // 'message:listUnread': function (messages) {
    //   this.$store.dispatch('messaging/setUnread', messages)
    // }
    // 'message:getAll': function (message) {
    //   console.log('listall>>>>><', message)
    // }
    'message:notification': function (message) {
      if (this.activeResidential.residentialId === message.residentialId) {
        this.$store.dispatch('messaging/newMessageReceived', message)
      }
    }
  },
  computed: {
    ...mapGetters('loading', ['isLoading']),
    ...mapGetters('oauth', ['isAuthenticated']),
    ...mapGetters('residential', ['activeResidential'])
  },
  watch: {
    activeResidential () {
      this.setSocketUser()
    },
    isAuthenticated () {
      this.setSocketUser()
    }
  },
  methods: {
    ...mapActions('user', ['loadUser']),

    async setSocketUser () {
      if (this.isAuthenticated && this.activeResidential.residentialId > 0) {
        const user = await this.loadUser(this.activeResidential.residentialId)
        user.specs = 'ADMIN'
        this.$socket.emit('user:set', user)
        // this.$socket.emit('messages:listAll', {})
      }
    }
  },
  mounted () {
    this.$socket.on('connect', () => {
      if (this.isAuthenticated) {
        this.setSocketUser()
      }
    })
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
