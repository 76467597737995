import axios from 'axios'
import globals from '@/utils/globals'
import store from '@/store/index'

const baseURL = globals.messagingApiUrl

const messagingApi = axios.create({ baseURL, timeout: 12500 })

messagingApi.interceptors.request.use(config => {
  const language = store.getters['language/selectedLanguage']
  const token = store.getters['oauth/authToken']
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  if (language) {
    config.headers['Accept-Language'] = language
  }
  return config
})

messagingApi.interceptors.response.use(response => {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('oauth/logOut').then(() => {})
    window.location.href = '/auth/signin'
  }
  return Promise.reject(error)
})

export default messagingApi
