export default [{
  path: '/digitalLibrary',
  meta: { requiresAuth: true, requiresResidential: true },
  component: () => import('@/layouts/MainTemplate'),
  children: [
    {
      path: '',
      component: () => import('@/views/DigitalLibrary/DigitalLibrary')
    }
  ]
}]
