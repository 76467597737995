export default [{
  path: '/auth',
  component: () => import('@/layouts/AuthTemplate'),
  children: [
    { path: '', component: () => import('@/views/Auth/SignIn') },
    { path: 'signin', component: () => import('@/views/Auth/SignIn') },
    { path: 'forgotPassword', component: () => import('@/views/Auth/ForgotPassword/Index') },
    { path: 'emailSent', component: () => import('@/views/Auth/ForgotPassword/EmailSent') },
    { path: 'resetPassword/:token/:source', component: () => import('@/views/Auth/ResetPassword') },
    { path: 'mobilePasswordSetted', component: () => import('@/views/Auth/ForgotPassword/MobilePasswordSetted') },
    { path: '*', redirect: { path: '/auth/signin' } }
  ]
}]
