import { getSocialAreaTypes } from '@/api/socialArea'

export default {
  namespaced: true,
  state: {
    AreaTypes: []
  },
  mutations: {
    SET_AREAS_TYPES (state, payload) {
      state.AreaTypes = payload
    }
  },
  getters: {
    allAreasTypes: state => state.AreaTypes,
    formatedAreasTypes: (state, getters) => {
      return getters.allAreasTypes.map(obj => { return { id: obj.id, description: obj.name } })
    },
    getIconByID: (state, getters) => (id) => {
      return getters.allAreasTypes.find(elem => elem.id === id).icon
    },
    getIconByName: (state, getters, rootState, rootGetters) => (name) => {
      return rootGetters['socialAreas/allAreas'].find(
        area => {
          if (area.name === name) {
            return area
          }
        }).socialAreaType.icon
    }
  },
  actions: {
    loadTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        getSocialAreaTypes().then((r) => {
          // console.log('area types')
          // console.log(r.data)
          // const formatedAreasTypes = r.data.map(obj => { return { id: obj.id, description: obj.name } })
          commit('SET_AREAS_TYPES', r.data)
          resolve(r)
        })
          .catch((error) => reject(error))
      })
    }
  }
}
