import { createStore } from 'vuex'

import oauth from './modules/oauth'
import loading from './modules/loading'
import residential from './modules/residential'
import reservation from './modules/reservation'
import socialAreas from './modules/socialAreas'
import socialAreasTypes from './modules/socialAreasTypes'
import messaging from './modules/messaging'
import user from './modules/user'
import bulletinBoard from './modules/bulletinBoard'
import spacesManagement from './modules/spacesManagement'
import chargeAccount from './modules/chargeAccount'
import payment from './modules/payment'
import pqrs from './modules/pqrs'
import routes from './modules/routes'
import digitalLibrary from './modules/digital-library'
import language from './modules/language'
import surveys from './modules/surveys'

export default createStore({
  state: {
    io: {}
  },
  mutations: {},
  actions: {},
  modules: {
    oauth: oauth,
    loading,
    residential,
    reservation,
    socialAreas: socialAreas,
    types: socialAreasTypes,
    user,
    messaging,
    bulletinBoard: bulletinBoard,
    spacesManagement: spacesManagement,
    chargeAccount,
    payment,
    pqrs,
    routes,
    digitalLibrary,
    language,
    surveys
  }
})
