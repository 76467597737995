const globals = {

  // ! PRODUCTION ENV
  apiUrl: 'https://zet-function.azurewebsites.net/api',
  socketUrl: 'https://zetmessaging.eastus.cloudapp.azure.com:2016',
  messagingApiUrl: 'https://zetmessaging.eastus.cloudapp.azure.com:2016/api',

  // QA ENV
  // apiUrl: 'https://zibi-qa-api.azurewebsites.net/api',
  // socketUrl: 'https://zibiqamessaging.eastus.cloudapp.azure.com:2016',
  // messagingApiUrl: 'https://zibiqamessaging.eastus.cloudapp.azure.com:2016/api',

  // DEV ENV (LOCAL)
  // apiUrl: 'http://localhost:5000/api',
  // socketUrl: 'http://localhost:2016',
  // messagingApiUrl: 'http://localhost:2016/api',

  // DEV ENV
  // apiUrl: 'https://zibi-dev-api.azurewebsites.net/api',
  // socketUrl: 'https://zibi-dev-messaging.eastus.cloudapp.azure.com:2016',
  // messagingApiUrl: 'https://zibi-dev-messaging.eastus.cloudapp.azure.com:2016/api/',

  publicUrl: process.env.BASE_URL,

  templateUploadResidents: 'https://zetstorage.blob.core.windows.net/templates/LIZI - Excel Carga Masiva de Residentes.xlsx',
  urlTermsAndConditions: 'https://www.lizi.la/términos-y-condiciones',
  defaultAvatar: 'https://zetstorage.blob.core.windows.net/avatar/default_avatar.png',

  httpStatus: {
    OK: 200,
    CREATED: 201
  },

  status: {
    ACTIVE: 'ACTIVE',
    PENDING: 'PENDING',
    INACTIVE: 'INACTIVE',
    CANCELLED: 'CANCELLED',
    PENDING_ACTIVATION: 'PENDING_ACTIVATION'
  },

  passwordRules: {
    minLength: 4,
    upperCaseIncluded: true,
    numberIncluded: true,
    specialCharacterIncluded: false
  },

  storageNames: {
    authTokenKeyName: 'a_tkn',
    activeResidential: 'active_residential',
    userEmail: 'user_email'
  },

  emails: {
    contact: 'ideas@wuhk.app'
  },

  stores: {
    ios: 'https://apps.apple.com/us/app/photos/id1584215428',
    android:
      'https://play.google.com/store/apps/details?id=com.disney.disneyplus'
  },

  prefixQR: {
    building: 'https://zetstorage.blob.core.windows.net/scans/building.html?id='
  },

  defaultControlReservationDays: 30
}

export default globals
