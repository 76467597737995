import apiApp from '@/api/config/apiApp'

function listChargeAccountTypes () {
  return apiApp.get('/charge-accounts/types')
}

function listChargeAccounts (residentialId) {
  return apiApp.get(`/charge-accounts?residentialId=${residentialId}`)
}

function createChargeAccount (form) {
  return apiApp.post('/charge-accounts', form)
}

function changeStatusChargeAccount (data) {
  return apiApp.post('/charge-accounts/status', data)
}

export {
  listChargeAccountTypes,
  listChargeAccounts,
  createChargeAccount,
  changeStatusChargeAccount
}
